<template>
  <div id="roadmap" class="w-full bg-dark p-5 lg:p-10 flex flex-col items-start">
    
    <div class="container m0-auto">
      <SectionHeader text="ROADMAP" :center="true" :padding="true"/>
      <p class="text-center text-base lg:text-lg font-mono text-white">
        The roadmap is a high level view of our goals, we discuss more in the discord.
      </p>

      <div class="steps-progress flex-col items-center flex justify-center text-white py-10 m0-auto">
        <ul class="steps-list w-full relative list-none">
          <li class="steps-item py-7">
            <p class="text-base md:text-xl lg:text-2xl text-mono">Initial website, marketing, Season 1: 3 Prisoner Pigz</p>
          </li>
          <li class="steps-item py-7">
            <p class="text-base md:text-xl lg:text-2xl text-mono">Season 2: Warden's Wolves, Partnerships</p>
          </li>
          <li class="steps-item py-7">
            <p class="text-base md:text-xl lg:text-2xl text-mono">Season 3: To be Revealed, Expansion into Metaverse</p>
          </li>
          <li class="steps-item py-7">
            <p class="text-base md:text-xl lg:text-2xl text-mono">Season 4: To be Revealed, Beast Mode Game mechanics.</p>
          </li>
          <li class="steps-item py-7">
            <p class="text-base md:text-xl lg:text-2xl text-mono">Season 5: To be Revealed, TBD</p>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import SectionHeader from './SectionHeader.vue'

export default {
  components: {
    SectionHeader
  },
}
</script>

<style lang="scss" scoped>
@import '../scss/index.scss';
.steps-progress {

  .steps-list {
    &::before {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 10px;
      height: 100%;
      border-left: 3px solid $danger;
    }
    p {
      cursor: pointer;
    }
    .steps-item {
      position: relative;
      width: 50%;
      &:hover {
        &:nth-child(even)::before {
          height: 100%;
        }
        &:nth-child(odd)::before {
          height: 100%;
        }
      }
      &:nth-child(even) {
        padding-right: 40px;
        text-align: right;
        float: left;
        clear: both;
        // text-align: left;
    
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: -3px;
          width: 3px;
          height: 0;
          background-color: $secondary;
          display: inline-block;
          transition: all 255ms ease-in-out;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -9px;
          width: 15px;
          height: 15px;
          // border-radius: 50%;
          background-color: $danger;
          display: inline-block;
        }
      }
      &:nth-child(odd) {
        float: right;
        padding-left: 40px;
        clear: both;
        text-align: left;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 0;
          background-color: $secondary;
          display: inline-block;
          transition: all 255ms ease-in-out;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -6px;
          width: 15px;
          height: 15px;
          // border-radius: 50%;
          background-color: $danger;
          display: inline-block;
        }
      }
      // &:not(:last-child) {
      //   padding-bottom: 1rem;
      // }
    }
  }
}
</style>