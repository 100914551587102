<template>
  <div class="side-bar h-full flex-col fixed top-0 bg-slate w-full">
    <div class="w-full flex items-center justify-end p-5">
      <button
        type="button"
        class="inline-flex justify-center px-4 d text-lg font-medium text-white hover:text-danger bg-dnager border border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        @click="close"
      >
        X
      </button>
    </div>
     <ul class="flex flex-col items-center justify-start">
        <li v-for="link in items" :key="link.text" class="text-white p-3  my-1 lg:my-3 hover:opacity-60">
          <a class="font-kabal font-medium text-xl" :href="link.link" @click="close">{{link.text}}</a>
        </li>
      </ul>

      <div class="absolute bottom-0 w-full items-center flex justify-center py-10">
        <Logo fontSize="text-3xl" logoWidth="w-10" textClasses="ml-3"/>
      </div>
  </div>
</template>

<script>
import Logo from './Logo.vue';

export default {
  props: ['open', 'close', 'items', 'opened'],
  computed: {
    show() {
      this.opened == true;
    }
  },
  components: {
    Logo
  }
}
</script>


<style lang="scss" scoped>
@import '../scss/index';
.side-bar {
  top: 0%;
  position: fixed;
  left: -100%;
  @include stagger();

  &.show {
    left: 0;
  }
}
</style>