<template>
  <div  id="social">
    <div class="w-full flex-col  items-center justify-center p-10 pb-5">
      <SectionHeader :padding="false" :size="'text-3xl'" text="Stay connected"/>

      <div class="footer-social pt-5">
        <a target="_blank" href="https://www.instagram.com/pris0nerz">
          <font-awesome-icon class="text-white text-3xl cursor-pointer transition hover:text-danger mx-5" :icon="['fab', 'instagram']" />
        </a>
        <a target="_blank" href="https://twitter.com/pris0nerz">
          <font-awesome-icon class="text-white text-3xl cursor-pointer transition hover:text-danger mx-5" :icon="['fab', 'twitter']" />
        </a>
        <a target="_blank" href="https://discord.gg/GeUCW6QFf9">
          <font-awesome-icon class="text-white text-3xl cursor-pointer transition hover:text-danger mx-5" :icon="['fab', 'discord']" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from './SectionHeader.vue'

export default {
  components: {
    SectionHeader
  },  
}
</script>

<style lang="scss" scoped>

</style>