<template>
  <div class="w-full p-5 lg:p-10 flex flex-col items-start bg-slate" id="faqs">
    <SectionHeader text="FAQS" :center="true" :padding="true"/>
    <div class="container m0-auto">
     <Accordion>
       <AccordionContent
        v-for="item in content"
        :key="item.title"
        :title="item.title"
       >
         <div class="accordion-content p-3 lg:p-5">
           <div class="text-left text-sm lg:text-base text-white font-mono px-5" v-html="item.content"></div>
         </div>
       </AccordionContent>
     </Accordion>
    </div>
  </div>
</template>

<script>
import Accordion from './Accordion.vue';
import AccordionContent from './AccordionContent.vue';
import SectionHeader from './SectionHeader.vue';
import {content} from '../config/content.js';

export default {
  components: {
    Accordion,
    AccordionContent,
    SectionHeader
  },
  data() {
    return {
      content
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../scss/index.scss';

.accordion-content {
  opacity: 0;
  animation: fadeIn.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  animation-fill-mode: forwards;
} 

</style>
