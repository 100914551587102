<template>
  <div class="flex items-center justify-center relative">
    <div 
      class="locked-season cursor-pointer relative tranistion-all flex items-center justify-center h-96 bg-gray-900 mt-10 box-border r w-full" 
      :class="{[seasonNumber]: season}">
      <p class="font-mono text-xl lg:text-4xl text-white p-6 border-8 border-danger pointer z-10 pointer-events-none">SEASON {{season}} LOCKED</p>
    </div>
  </div>
</template>

<script>
import Modal from './Modal.vue'

export default {
  props: ['season', 'text'],
  components: {
    Modal
  },
  data() {
    return {
      isOpen: false,
      seasonNumber: `season_${this.season}`
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/index';

.locked-season {
  border: 8px solid transparent;

  &:hover {
    border: 8px solid $danger;
    p {
      border-color: $accent;
    }
    @include before() {
      background-color: $accent;
    }  
  }
  
  @include before() {
    background-color: $primary;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url('/assets/seasons/wolf_base_blur.png');
    filter: blur(6px);
    opacity: .15;
    z-index: 0;
    transition: .4s background-color ease-in-out;
  }
}
</style>