// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKZ_1EY5LHRjSqwDGS-i8RSyFP3QqVvxM",
  authDomain: "polygon-prisoners-5b76c.firebaseapp.com",
  projectId: "polygon-prisoners",
  storageBucket: "polygon-prisoners.appspot.com",
  messagingSenderId: "632912136715",
  appId: "1:632912136715:web:c3b7011253d2466a79d50e",
  measurementId: "G-YH33WPDR35"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);