<template>
  <div class="box-border" :class="{ last: last === true, first: first === true}">
    <div class="title px-4 lg:px-6 py-6 cursor-pointer flex items-center justify-start box-border w-full bg-dark my-3 border-2 " 
      :class="{
        'border-purp': active,
        'border-dark': !active
      }" 
      @click="active = !active">
      <h5 class="m-0 text-base text-left lg:text-xl font-semibold font-kabal text-white">{{title}}</h5>
    </div>
     <!-- <TransitionRoot appear :show="active">
      <TransitionChild
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          
      </TransitionChild>
     </TransitionRoot> -->
     <slot v-if="active"/>
  </div>
</template>

<script>
import {ref} from 'vue';
import {
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'


export default {
  props: ['title', 'first', 'last'],
  components: {
    TransitionRoot,
    TransitionChild,
  },
  setup(props, context) {
    const active = ref(false);

    return {
      active,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../scss/index.scss';

.accordion-transition-enter-active, 
.accordion-transition-leave-active, 
.title {  
  @include stagger();
}
</style>