<template>
  <div class="divider w-full" :class="{
    'slate': slate, 
    'no-mobile': hideOnMobile,
    'accent': accent, 
    'purp': purp, 
    'danger': danger, 
    'warning': warning, 
    'small': small
  }"></div>
</template>

<script>
export default {
  name: 'Divider',
  props: ['slate', 'hide-on-mobile', 'accent', 'danger', 'warning', 'purp', 'small']
}
</script>

<style lang="scss" scoped>
@import '../scss/index';

.divider {
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    $danger,
    $secondary,
    $accent
  );
  background: linear-gradient(
    to right,
    $danger,
    $secondary,
    $accent
  );
  height: 4px;
  &.slate {
    background: $slate; // #121832;
    opacity: .4;

    .dark & {
      opacity: 1;
      background: $primary;
    }
  }

  &.accent {
    background: $accent; // #121832;
  }
  &.purp {
    background: $purp; // #121832;
  }
  &.warning {
    background: $warning; // #121832;
  }
  &.danger {
    background: $danger; // #121832;
  }
  &.small {
    height: 2px;
  }

  &.no-mobile {
    .mobile & {
      display: none;
    }
  }
}
</style>