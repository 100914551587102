<template>
   <div 
    class="flex items-center"
    :class="{
      'flex-col': stacked,
      'flex-row': !stacked,
    }">
      <img src="../assets/splash/white-circle-logo.png" 
        :class="{
          [logoWidth]: logoWidth,
          'w-32': !logoWidth,
          [logoClasses]: logoClasses
        }"
      />
      <div class="poly-logo relative my-3">
        <p 
          class="poly-logo--bottom text-white font-fasthand"
          :class="{
            [fontSize]: fontSize,
            'text-7xl': !fontSize,
            [textClasses]: textClasses
          }"
          >
          Pris0nerz
        </p>
      </div>
  </div> 
</template>

<script>
export default {
  props: ['stacked', 'logoWidth', 'fontSize', 'textClasses', 'logoClasses']
}
</script>


<style lang="scss" scoped>
@import '../scss/index';

.poly-logo {
  .poly-logo--bottom {
    // font-size: 68px;
    // color: #FFFFFF;
    // text-align: center;
    // position: relative;
    // font-size: $logo_bottom_size;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.91);

    &.nav {
      @include after() {                
        top: 43%;
        left: 38%;
        width: 70px;
        height: 4px;
        background: #FFFFFF;
        transform: rotate(-57deg);
        z-index: 1001;
      }
    }

  }
}
</style>
