<template>
  <div class="pz-button">
    <button 
      type="type" 
      class="text-white border p-2 px-7 hover:opacity-75 transition-opacity rounded-sm text-mono text-lg"
      :class="{'bg-slate': color === 'dark'}"
    >{{text}}</button>
  </div>
</template>

<script>
export default {
  props: ['type', 'text', 'color']
}
</script>

<style lang="scss" scoped>
.pz-button {

}
</style>