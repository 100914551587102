<template>
  <div id="team" class="w-full bg-slate p-5 lg:p-10 flex flex-col items-start">
    
    <div class="container m0-auto">
      <SectionHeader text="TEAM" :center="true" :padding="true"/>
      <p class="text-center text-base lg:text-lg font-mono text-white">
        The team is filled of outlaws with decades of experience in tech and business. With backgrounds covering startups, big tech and everything in between. Their resumes include names like JP Morgan Chase, Google, Intuit, Moody's, eBay, Symantec and more.
      </p>

      <div class="flex flex-col xl:flex-row w-full items-center xl:items-start justify-center pb-10 mt-10">
   
         <div class="my-4">
          <p class="font-kabal text-2xl xl:text-4xl text-white py-2">Piglet 1: Art</p>
          <div class="border-danger border-8 pt-5 my-3 lg:my-0 lg:mx-2">
            <img class="lg:w-80" src="../assets/ice.png" />
          </div>
          <p class="w-60 lg:w-80 text-center  text-white text-mono text-base px-4 pt-2 m0-auto" m0-auto>{{comments.piglet_5_6}}</p>
        </div>
        <div class="my-4">
          <p class="font-kabal text-2xl xl:text-4xl text-white py-2">Piglet 2: Dev</p>
          <div class="border-danger border-8 pt-5 my-3 lg:my-0 lg:mx-2">
            <img class="lg:w-80" src="../assets/Lava.png" />
          </div>
          <p class="w-60 lg:w-80 text-center  text-white text-mono text-base px-4 pt-2 m0-auto">{{comments.piglet_5_6}}</p>
        </div>

        <div class="my-4">
          <p class="font-kabal text-2xl xl:text-4xl text-white py-2">Piglet 3: Sales</p>
          <div class="border-danger border-8 pt-5 my-3 lg:my-0 lg:mx-2">
            <img class="lg:w-80" src="../assets/Zombie.png" />
          </div>
          <p class="w-60 lg:w-80 text-center  text-white text-mono text-base px-4 pt-2 m0-auto">{{comments.piglet_5_6}}</p>
        </div>
        
        <div class="my-4">
          <p class="font-kabal text-2xl xl:text-4xl text-white py-2">Piglet 4: Dev</p>
          <div class="border-danger border-8 p pt-5 my-3 lg:my-0 lg:mx-2">
            <img class="lg:w-80" src="../assets/Poison_Dart.png" />
          </div>
          <p class="w-60 lg:w-80 text-center text-white text-mono text-sm px-4 pt-2 m0-auto">{{comments.piglet_4}}</p>
        </div>

        <div class="my-4">
          <p class="font-kabal text-2xl xl:text-4xl text-white py-2">Piglet 5&6: Dev</p>
          <div class="border-danger border-8 pt-5 my-3 lg:my-0 lg:mx-2">
            <img class="lg:w-80" src="../assets/Swamp.png" />
          </div>
          <p class="w-60 lg:w-80 text-center  text-white text-mono text-base px-4 pt-2 m0-auto">{{comments.piglet_5_6}}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SectionHeader from './SectionHeader.vue'
import {comments} from '../config/content';
export default {
  components: {
    SectionHeader
  },
  data() {
    return {
      comments
    }
  }
}
</script>