<template>
  <div class="work-divider w-full relative">
    <div class="words h-1/2 w-full opacity-50"></div>
    <div class="words-2 h-1/2 w-full opacity-50"></div>
  </div>
</template>

<style lang="scss" scoped>
@import '../scss/index';

.work-divider {
  min-height: 600px;
  height: 600px;
  // background: rgb(63,94,251);\
  // background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  // background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  // background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 70%, #F76B1C 100%);
  background: linear-gradient(90deg, $secondary 0%, $danger 70%, $warning 100%);
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	// background-size: 400% 400%;
	// animation: gradient 15s ease infinite;
	// height: 100vh;


  .words {
    background-image: url('../assets/pr_04.png');
    animation: animatedBackground 10s linear infinite;
    z-index: 10;
  }
  .words-2 {
    background-image: url('../assets/pr_04.png');
    animation: animatedBackgroundAlt 10s linear infinite;
    z-index: 10;
  }
}

</style>