import { createApp } from 'vue'
import { Vue3Mq } from "vue3-mq";
import AOS from "aos";

import App from './App.vue'
import store from './store'
import router from './router'

import {analytics} from './firebase';

import 'aos/dist/aos.css'
import './index.css';
import './index.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus, faWindowClose, faBars, faClock, faEquals, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/fontawesome-free-brands'


library.add(faDiscord);
library.add(faArrowRight);
library.add(faWindowClose);
library.add(faBars);
library.add(faPlus);
library.add(faEquals);
library.add(faClock);

App.AOS = new AOS.init();

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(AOS)
  .use(Vue3Mq, {
    preset: 'tailwind'
  })
  .mount('#app')
