<template>
  <div id="seasons" class="w-full p-6 lg:p-10 flex flex-col items-start">
    <div class="container m0-auto">
      <SectionHeader text="SEASONS" :center="true" :padding="true"/>
      <PrisonerCard :prisoner="seasonData[0]" />

      <div class="w-full flex flex-col pb-10" @click="checking($event)">
        <div class="w-full flex flex-col lg:flex-row items-center justify-between">
          <LockedSeason season="2" class="w-full lg:w-1/2" :text="seasonsText.season_2" />
          <LockedSeason season="3" class="w-full lg:ml-5 lg:w-1/2" :text="seasonsText.season_3" />
        </div>
        <div class="w-full flex flex-col lg:flex-row items-center justify-between">
          <LockedSeason season="4" class="w-full lg:w-1/2" :text="seasonsText.season_4" />
          <LockedSeason season="5" class="w-full lg:ml-5 lg:w-1/2" :text="seasonsText.season_5" />
        </div>
      </div>
    </div>

    <Modal :isOpen="isOpen" :close="close" :open="open" :title="modalTitle">
      <div>
        <h3 class="font-kabal uppercase text-white text-3xl">{{modalText.title}}</h3>
        <p class="text-white font-mono text-lg py-5">{{modalText.text}}</p>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from './SectionHeader.vue'
import PrisonerCard from './PrisonerCard.vue'
import LockedSeason from './LockedSeason.vue'
import {prisoner} from '../config/prisoner'
import Modal from './Modal.vue'

const seasonsText = {
  season_2: {
    title: 'SEASON 2',
    text: `Warden's Wolves, Partnerships`
  },
  season_3: {
    title: 'SEASON 3',
    text: `To be Revealed, Expansion into Metaverse`
  },
  season_4: {
    title: 'SEASON 4',
    text: `To be Revealed, Beast Mode Game mechanics.`
  },
  season_5: {
    title: 'SEASON 5',
    text: 'To be Revealed, TBD'
    
  }
}

export default {
  components: {
    SectionHeader,
    PrisonerCard,
    LockedSeason,
    Modal
  },
  data() {
    return {
      seasonData: prisoner,
      seasonsText,
      modalTitle: undefined,
      modalText: undefined,
      isOpen: false,
    }
  },
  methods: {
    checking(e) {
      const [key] = [...e.target.classList.values()].filter(item => item.includes('season_'))
      this.modalTitle = key;
      this.modalText = this.seasonsText[key]
      this.open()
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  }
}
</script>