<template>
  <div id="about" class="w-full p-5 lg:p-10 flex flex-col items-start ">
    <div class="container m0-auto">
      <Card class="py-5" :illuminated="false" bg="bg-slate">
        <div class="flex flex-col lg:flex-row">
          <div class="w-full lg:w-1/3 p-6">
            <img src="../assets/locked_fast.gif" pig="0" class=" border-8 border-white" />
          </div>

          <div class="w-full lg:w-2/3 p-5 lg:p-10">
           <SectionHeader text="ABOUT" :center="true" :padding="false"/>
            <p class="text-center py-5 font-mono text-sm lg:text-lg text-white">Welcome to Prisonerz, a dynamic NFT project, where your choices matter, and where it's never too late to change! What you do and who you become rests solely on your shoulders, so your decisions outweigh the randomness of your initial mint. Will you choose the safe route and obey the Warden's Wolves? Or will you rebel, plan your escape and make your way to some secluded island with little miss piggy? Depending on what you choose, your NFT will access exclusive $contraband, which you may choose to morph yourself with or
smuggle to other Prisonerz.</p>
          </div>
        </div>
      </Card>

      

      <div class="flex flex-col lg:flex-row">
        <div class="w-full lg:w-1/3 py-6 lg:px-6">
          <img src="../assets/pig_mask.png" class="border-8 border-danger"/>
        </div>
        <div class="w-full lg:w-1/3 py-6 lg:px-6">
          <img src="../assets/gabe.png" class=" border-8 border-danger"/>
        </div>
        <div class="w-full lg:w-1/3 py-6 lg:px-6">
          <img src="../assets/seth_edit.png" class=" border-8 border-danger"/>
        </div>
      </div>

      <p class="py-6 text-center text-sm lg:text-xl font-mono text-white">
        Prisoner NFT's are dynamic! They change as you play the game! Your choices equate to NFT outcomes. As you and others play the game, the rarity adjusts according to what's created and what's left behind!
      </p>
    </div>
  </div>
</template>

<script>
import SectionHeader from './SectionHeader.vue'
import Card from './Card.vue'


export default {
  components: {
    SectionHeader,
    Card
  },
  data() {
    return {
      isOpen: false,
      selected: undefined
    }
  },
  methods: {
    open(e) {
      this.selected = e.target.getAttribute('src');
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  }
}
</script>
