<template>
    <div class="card relative flex items-center justify-center" :class="{'illuminated': illuminated}">
      <div class="z-10 relative py-5 lg:px-5 lg:py-5 lg:p-10" :class="{[bg]: bg}">
        <slot/>
      </div>
    </div>
</template>

<script>
export default {
  props: ['illuminated', 'bg']
}
</script>

<style lang="scss" scoped>
@import '../scss/index';
.card {
  &.illuminated {
    @include after() {
      margin: 0 auto;
      background: $danger;
      filter: blur(88px);
      // z-index: -1;
      border-radius: 100px;
      width: 90%;
      height: 60%;
      left: 50%;
      top:50%;
      transform:translate(-50%,-50%);
    }
  } 
}
</style>
