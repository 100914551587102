<template>
  <div class="top-nav fixed flex flex-col items-center justify-around top-0  z-50 w-full  bg-dark">
    <div class="w-full flex items-center justify-between m0-auto px-10">
      <Logo fontSize="text-3xl" logoWidth="w-10" textClasses="ml-3"/>
      <ul class="flex flex-row items-center justify-start" :class="{'hidden': mq.smMinus }">
        <li v-for="link in links" :key="link.text" class="text-white p-3 hover:opacity-60">
          <a class="font-kabal font-medium" :href="link.link">{{link.text}}</a>
        </li>
        <li class="hidden">
          <Button text="Learn more" />
        </li>
      </ul>
      <div class="flex md:hidden" @click="openNav()">
        <font-awesome-icon class="text-white text-3xl cursor-pointer transition over:opacity-75 mx-3" :icon="['fa', 'bars']" />
      </div>
    </div>
    <Divider :danger="true" :small="true" />

    <SideBar :opened="isOpen" :open="openNav" :close="closeNav" :items="links" :class="{'show': isOpen}" v-click-outside="closeNav"/>
  </div>
</template>

<script>
import { ClickOutside } from "vue-click-outside-of";
import Button from './Button.vue';
import Divider from './Divider.vue';
import Logo from './Logo.vue';
import SideBar from './SideBar.vue';


export default {
  name: 'Navigation',
  props: ['routes'],
  inject: ['mq'],
  directives: { ClickOutside },
  data() {
    return {
      isOpen: false,
      links: [
        {
          text: 'HOME',
          link: '#home'
        },
        {
          text: 'ABOUT',
          link: '#about'
        },
        {
          text: 'SEASONS',
          link: '#seasons'
        },
        {
          text: 'TEAM',
          link: '#team'
        },
        {
          text: 'ROADMAP',
          link: '#roadmap'
        },
        {
          text: 'FAQS',
          link: '#faqs'
        },
        {
          text: 'SOCIAL',
          link: '#social'
        },
      ]
    }
  },
  methods: {
    openNav() {
      this.isOpen = true;
      console.log()
    },
    closeNav() {
      this.isOpen = false;
    }
  },
  components: {
    Divider,
    Logo,
    Button,
    SideBar
  }
}
</script>

<style lang="scss">
.top-nav {
  // height: 72px;
  // background: radial-gradient(at 50% 100%, #797979 0, #000 100%);
}
</style>