export const content = [
  {
    title: "What is prisønerz?",
    content: `An NFT game, where the more you play, the more rare your nft becomes. Your in-game decisions result in unique character morphs & outcomes unlocking rarity & exclusive prison benefits in & out of the prisonverse.`
  },
  {
    title: "What makes it gamified?",
    content: `Upon the first collection drop, a multi-level game will be immediately released allowing our community to utilize their prisøner nft for a custom storyline resulting in unique rarity based on in-game decisions.`
  },
  {
    title: "Is it P2E?",
    content: `Our experience would be classified as a play & earn versus play 2 earn outcome-based RPG`,
  },
  {
    title: "Why?",
    content: `The goal of our team is to establish infectious gameplay in an economical fashion. With a team born & raised with a passion for gaming & anime, there is nothing more frustrating than finances preventing you from a fully satisfying gaming experience. Although with a gaming first mentality, there will be monetary benefits through secondary sales as well as numerous other means to unpacked at a later time.`
  },
  {
    title: "What makes your NFT game different from all others out there?",
    content: `Gameplay is our priority. Proof? Upon completion of our public mint, our community will be able to experience V1 of the game immediately after. Compared to a road map achievement that is scheduled far out.`
  },
  {
    title: "Do I have to play the game?",
    content: `No. Not playing the game could also be quite an advantageous strategy.`
  },
  {
    title: "How?",
    content: `As users start to play the game their NFT will burn & evolve into a different NFT as a result of their in-game decisions. Thus making the original prisøners deflationary creating organic rarity.`
  },
  {
    title: "Why did you choose polygon for this project?",
    content: `Polygon we felt was the best fit for season 1 as it is economic, scalable, interoperable, & has some of the fastest transaction speeds in the crypto space.`
  },
  {
    title: "How many prisønerz are there?",
    content: `There will be one species of prisønerz released for every season. Each season contains 3 bus rides (mints) transporting the prisønerz. There will be 5 seasons that contain their own (storyline) & levels of gameplay with community stakeholdership playing a major part in its development`
  },
  {
    title: "How can we determine the rarity for our prisøner?",
    content: `A rarity bot will be released upon the completion of the first mint. In order to capture true rarity, one will have to complete the game.`
  },
  {
    title: "Who is the team behind this? Will you be doxed?",
    content: `
      <p>"If you don’t believe it or don’t get it, I don’t have the time to try to convince you, sorry." </p>
      – Satoshi Nakamoto <br />

      <p class="py-6">Our team is made up of developers, artists, & tech-startupreneurs all unified for one purpose….. Disruption of the web 3.0 gamespace in an instantaneous, accessible, & affordable fashion for all.</p>

      We are a bootstrapped passion project dripping sweat equity where the most important currency of all is…….. our community. We will grow, change, & evolve together as one with our proof of concept being validated through consistent & consecutive delivered work. 
    `
  },
  {
    title: "What will you do with the earnings?",
    content: `
      <p>We look at season 1 as our PlayStation, Nintendo, Sega Genesis, or any other generation 1 console release. We will leverage the working capital to enhance bandwidth, gameplay, art, community & overall user experience.</p>
      <p class="py-6">Ultimately our intention is to evolve from season to season like the ps1 to ps5 evolution of gameplay. (except not as long a wait!). Also with the development & the direction of the project operating like a DAO where the community stakeholdership holds the influence</p>
    `
  },
  {
    title: "Want to know more? Check out our white paper",
    content: `
    <a target="_blank" href="../assets/PRISON_PAPER.pdf" class="text-white font-mono underline mx-2">PRISON PAPER</a>
    `
  },
]

export const comments = {
  piglet_1: '...',
  piglet_2: '...',
  piglet_3: '...',
  piglet_4: `I'm not poisonous but like my code, don't test me!`,
  piglet_5_6: '...'
}