<template>
  <div id="viewport" class="flex flex-col" data-aos="zoom-up">
    <!-- <Splash /> -->
    <FaceOff />
    <Divider />
    <About />
    <WordDivider />
    <Timeline />
    <Team />
    <Roadmap />
    <CharacterDivider />
    <Divider />
    <!-- <Gamifacation /> -->
    <Faqs/>
    <Divider :accent="true" />
    <StayConnected />
    <Footer />
  </div>

</template>

<script>
import Divider from '../components/Divider.vue';
import FaceOff from '../components/FaceOff.vue';
import About from '../components/About';
import StayConnected from '../components/StayConnected.vue';
import Timeline from '../components/Timeline.vue';
import WordDivider from '../components/WordDivider.vue';
import Team from '../components/Team.vue';
import Roadmap from '../components/Roadmap.vue';
import Footer from "../components/Footer"; 
import CharacterDivider from '../components/CharacterDivider.vue';
import Gamifacation from '../components/Gamifacation.vue';
import Faqs from '../components/Faqs.vue';

export default {
  name: 'Home',
  components: {
    FaceOff,
    About,
    StayConnected,
    Timeline,
    WordDivider,
    Divider,
    Team, 
    Footer,
    Roadmap,
    CharacterDivider,
    Gamifacation,
    Faqs
  }
}
</script>

<style lang="scss" scoped>
#viewport {
  margin: {
    top: 60px;
  }
}
</style>