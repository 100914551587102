<template>
  <div class="character-divivder w-full bg-dark flex items-center justify-center overflow-hidden">
    <img src="../assets/pig-outline.png" />
    <img src="../assets/outline.png" />
    <img src="../assets/pig-outline.png" />
    <img src="../assets/outline.png" />
    <img src="../assets/pig-outline.png" />
    <img src="../assets/outline.png" />
    <img src="../assets/pig-outline.png" />
    <img src="../assets/outline.png" />
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.character-divivder {
  img {
    // animation: fadeIn 0.5s linear infinite;
    // animation-delay: 2s;
    &:nth-child(odd) {
      transform: scaleX(-1);
      // animation: fadeIn 0.5s linear infinite;
      // animation-delay: 4s;
    }
    opacity: .7;

    .is-mobile & {
      max-width: 50%;
    }
  }
}

// @keyframes fadeIn {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
</style>