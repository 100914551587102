<template>
  <footer class="container m0-auto pb-5">
    <div class="flex flex-col">
      <div class="w-full py-5 hidden">
        <!-- <a target="_blank" href="../assets/PRISON_PAPER_v0.7.pdf" class="text-white font-mono underline mx-2">Terms of use</a> -->
        <a target="_blank" href="../assets/PRISON_PAPER.pdf" class="text-white font-mono underline mx-2">White paper</a>
      </div>
      <a target="_blank" href="https://pris0nerz.io/" class="text-white opacity-50 hover:opacity-75 font-fasthand transition duration-500 text-xl py-2">pris0nerz.io</a>
      <!-- <p class="font-mono text-sm">TRUSTLESS &copy; 2022</p> -->
    </div>
  </footer>
</template>

<script>
// import Divider from './Divider.vue'

export default {
  components: {
    // Divider
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/index';

footer {
  text-align: center;
  // position: absolute;
  bottom: 10px;
}

.border-tron {
  border-color: $accent;
}
</style>