<template>
  <div class="flex flex-col items-start w-full" :class="{'is-mobile': mq.smMinus }">
    <TopNav :routes="routes" />
    <router-view class="flex flex-auto w-full"></router-view>
  </div>
</template>

<script>
import TopNav from "./components/TopNav.vue";
import {routes} from './router';

export default {
  name: "App",
  components: {
    // Footer11,
    TopNav
  },
  inject: ['mq'],
  data() {
    console.log(this.$route)
    return {
      routes
    }
  }
};
</script>

<style lang="scss">
@import './scss/index.scss';

#app {
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: $primary;
  /* background: radial-gradient(at 50% 100%,#1f1f3a 0,#000 100%); */
  height: 100%;
  width: 100%;
}

.m-50 {
  padding-top: 50px;
}
</style>
