<template>
  <div class="w-full">

    <!-- Text -->
    <p class="font-kabal uppercase text-white relative" :class="{
      'text-left': left, 
      'text-center': center, 
      'py-10': padding,
      'text-5xl': !size,
      [size]: size,
      [classes]: classses
    }">
      {{text}}
    </p>
    
  </div>
</template>

<script>
export default {
  props: ['text', 'left','center', 'padding', 'classes', 'size']
}
</script>

<style lang="scss" scoped>
.width-content {
  width: fit-content;
}
</style>

