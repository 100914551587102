<template>
  <div class="box-border">
    <slot />
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
  setup(props, context) {
    // const slotContent = (context.slots.default()[0].children.length) ? context.slots.default()[0].children : context.slots.default();
    // const titles = ref(slotContent.map((tabSlot) => tabSlot?.props.titles));
    // const selected = ref(titles.values[0]);
    // return {
    //   titles,
    //   selected
    // }
  },
}
</script>