<template>
  <div class="grid-texture w-full  relative overflow-hidden bg-white " :class="{'is-mobile': mq.smMinus }">
    <!-- BACKGROUND -->
    <div class="absolute h-full bg-white">
      <!-- <img :src="prisoner.bg" class="h-full" alt="" /> -->
    </div>

    <!-- CONTENTs -->
    <div class="max-h-[290px] h-auto flex flex-col lg:flex-row">
      <div 
        class="record flex flex-col items-start z-30 relative text-white w-full lg:w-2/3 px-5 lg:px-10 py-3" 
        :class="{
          'bg-warning season-1': prisoner.season === 1,
          'bg-blue-900 season-2': prisoner.season === 2,
          'bg-red-900 season-3': prisoner.season === 3
        }">

        <div class="flex flex-col items-start py-4 z-30">
          <div class="pb-2 flex flex-col lg:flex-row items-center md:items-start">
            <h3 class="font-kabal font-shadow text-xl lg:text-5xl pr-3">SEASON #{{prisoner.season}}:</h3>
            <h3 class="font-kabal font-shadow text-xl lg:text-5xl ">{{prisoner.title}}</h3>
          </div>

          <!-- <p class="font-mono font-medium text-lg uppercase"> {{prisoner.title}}</p> -->
          <p class="font-mono font-medium text-xs lg:text-sm">MINT DATE: {{prisoner.mintDate}}</p>
          <p class="font-mono font-medium text-xs lg:text-sm">MINT PRICE: {{prisoner.mintPrice}}</p>
          <p class="font-mono font-medium text-xs lg:text-sm text-left" v-html="prisoner.network"></p>
          <p class="font-mono font-medium text-xs lg:text-sm">QTY: {{formatPrice(prisoner.quanity)}}</p>

        </div>

        <div class="flex flex-col items-start  z-30 py-2">
          <p class="font-mono font-medium text-sm lg:text-base text-justify">{{prisoner.description}}</p>
          <p class="font-mono font-medium  text-sm lg:text-base text-justify pt-6">{{prisoner.details}}</p>
        </div>
        <img :src="prisoner.angle" class="triangle absolute bottom-[-100%]" alt="" />
      </div>
      
      <div class="charater h-auto flex items-end justify-center lg:justify-end  right-0 w-full lg:w-1/3 relative">
        <img :src="prisoner.character" class="character-img relative pt-3 z-40" alt="" />
        <p class="absolute bottom-0 z-40 font-phos text-white text-6xl py-3 lg:pr-12">{{prisoner.name}}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  inject: ['mq'],
  props: ['prisoner'],
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/index';

.font-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.50);
}
.grid-texture {
  background-image: url('/assets/bg/grid-texture.png');
  animation: animatedBackground 10s linear infinite alternate;
}
.charater {
  .character-img {
    max-width: 360px;

    .is-mobile & {
      max-width: 260px;
    }
  }
}
.triangle {
  right: -40%;
}
.record {
  &.season-1 {
    // @include after() {
    //   width: 0;
    //   height: 0;
    //   left: initial;
    //   right: -200px;
    //   border-style: solid;
    //   border-width: 540px 210px 0 150px;
    //   border-color: #b700ff transparent transparent transparent;
    //   z-index: -1;
    // }
    .triangle {
      top: 0;
      .is-mobile & {
        .triangle {
          top: initial;
          bottom: 100%;
        }

      }
    }
    
  }
  &.season-2 {
    @include after() {
      width: 0;
      height: 0;
      left: initial;
      right: -200px;
      border-style: solid;
      border-width: 540px 210px 0 150px;
      border-color: #1a1380 transparent transparent transparent;
      z-index: -1;
    }
  }
  &.season-3 {
    @include after() {
      width: 0;
      height: 0;
      left: initial;
      right: -200px;
      border-style: solid;
      border-width: 540px 210px 0 150px;
      border-color: #80135c transparent transparent transparent;
      z-index: -1;
    }
  }
  
}

.locked {
  @include before() {
    background: black;
    opacity: .5;
    z-index: -1;
  }
}
</style>
