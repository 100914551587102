<template>
  <div id="home" class="face-off w-screen flex flex-col items-center justify-center lg:justify-start  lg:pt-32 relative">

    <div class="flex flex-col items-center  justify-center h-full xl:mb-10">
      <img src="../assets/splash/white-circle-logo.png" class="w-24 lg:w-32"/>
      <div class="poly-logo relative my-3 lg:mb-16">
        <p class="poly-logo--bottom text-white font-fasthand text-5xl lg:text-7xl z-50">
          Pris0nerz
        </p>
      </div>
    </div> 

    <div class="absolute bottom-0">
      <img src="../assets/splash/characters_updated.png" alt="" class="w-11/12 xl:w-10/12  m0-auto"/>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@import '../scss/index';

// $logo_top_size: 1rem * 1.5;
// $logo_bottom_size: 3.6rem * 1.5;

.face-off {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../assets/splash/hallway.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 750px;

  .is-mobile & {
    min-height: 420px;
  }
  img {
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

.poly-logo {
  .poly-logo--bottom {
    // font-size: $logo_bottom_size;
    // font-size: 68px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.91);
    position: relative;

    // @include after() {
    //   background: #FFFFFF;
    //   height: 4px;
    //   width: 70px;
    //   left: 38%;
    //   top: 43%;
    //   transform: rotate(-57deg);
    //   z-index: 49;
    // }

  }
}

.stare-down {
  width: auto;
  max-width: 50%;

  &.pig {
    // bottom: -40px;
  }
  
  &.wolf {
    max-height: 500px;
    
    .wolf-image {
      bottom: 0;
      right: 0;
      position: absolute;
    }
    .wolf-image:nth-child(1) {
      left: 0;
    }
  }
}



</style>
