<template>
  <div  id="gamifacation">
    <div class="w-full flex-col bg-dark  items-center justify-center p-10">
     <div class="container m0-auto">
      <SectionHeader text="GAMIFICATION" :center="true" :padding="true"/>
      <p class="text-cetner text-lg font-mono text-white">
        Prisonerz is a gamified NFT experience, where the decisions you make result is unique character rarity, outcomes & status throughout the Prisønverse.
      </p>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from './SectionHeader.vue'

export default {
  components: {
    SectionHeader
  },  
}
</script>

<style lang="scss" scoped>
@import '../scss/index.scss';
</style>

